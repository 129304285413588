<template>
  <div>
    <DetailsViewBooking></DetailsViewBooking>
  </div>
</template>

<script>
import DetailsViewBooking from '@core/components/details-reservas/DetailsViewBooking.vue'

export default {
  components: {
    DetailsViewBooking,
  },
  data() {
    return {}
  },
}
</script>

<style>

</style>
